import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clients from "./assets/clients.json";

const useClientConfig = () => {
  const { client } = useParams();
  const [clientConfig, setClientConfig] = useState(null);

  useEffect(() => {
    if (client && clients[client]) {
      setClientConfig(clients[client]);
    } else {
      setClientConfig(clients["default"]); // Fallback to default config
    }
  }, [client]);

  return clientConfig;
};

export default useClientConfig;