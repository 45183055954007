import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import './Login.css';
import useClientConfig from "./useClientConfig";
import { Box, Typography, Link } from '@mui/material';
import TermsModal from './TermsModal';
import { supabase } from './supabaseClient';



const Login = () => {
    const [licensePlate, setLicensePlate] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [country, setCountry] = useState(null);
    const [termsChecked, setTermsChecked] = useState(false);
    const [errors, setErrors] = useState({ licensePlate: false, mobileNumber: false, country: false });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [generalError, setGeneralError] = useState('');

    const navigate = useNavigate();
    const clientConfig = useClientConfig();
    if (!clientConfig) {
        return <div>Loading...</div>; // Prevents the error
    }

    const options = countryList().getData().map(country => ({
        ...country,
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`https://flagcdn.com/w20/${country.value.toLowerCase()}.png`}
              alt={country.label}
              style={{ marginRight: 10 }}
            />
            {country.label}
          </div>
        ),
    }));

    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption);
    };
  
      // Allow only numbers and limit length to 9 or 10 for mobile number
      const handleMobileNumberChange = (e) => {
          const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
          if (value.length <= 10) {
              setMobileNumber(value);
          }
      };

    const logLoginAttempt = async (successful) => {
        try {
            const clientInfo = navigator.userAgent;
            const ipRes = await fetch('https://api.ipify.org?format=json');
            const { ip } = await ipRes.json();

            await supabase.from('login_attempts').insert([
                {
                    license_plate: licensePlate,
                    country_code: country?.value,
                    phone_number: mobileNumber,
                    successful,
                    ip_address: ip,
                    client_info: clientInfo,
                    company: client
                }
            ]);
        } catch (logError) {
            console.error('Error logging login attempt:', logError);
        }
    };

    const checkData = () => {
      const pathSegments = window.location.pathname.split("/").filter(Boolean);
      const client = pathSegments.length > 1 ? pathSegments[1] : "safe-city";

      const newErrors = {
        licensePlate: licensePlate.length < 1,
        mobileNumber: (client !== 'avis-israel' && ((mobileNumber.length < 9 || mobileNumber.length > 10) || (mobileNumber !== '0542224411' && mobileNumber !== '910586011'))) || (client === 'avis-israel' && (mobileNumber.length < 9 || mobileNumber.length > 10)),
        country: !country,
      };

      setErrors(newErrors);

      const isValid = !Object.values(newErrors).includes(true);
      if (!isValid) {
          logLoginAttempt(false);
      }

      return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (termsChecked && checkData()) {
            try {
                const clientInfo = navigator.userAgent;
                const ipRes = await fetch('https://api.ipify.org?format=json');
                const { ip } = await ipRes.json();
    
                const { error } = await supabase.from('login_attempts').insert([
                    {
                        license_plate: licensePlate,
                        country_code: country?.value,
                        phone_number: mobileNumber,
                        successful: true, 
                        ip_address: ip,
                        client_info: clientInfo,
                        company: client
                    }
                ]);
    
                if (error) {
                    console.error('Error logging login attempt:', error.message);
                    setGeneralError('Something went wrong. Please try again.');
                    logLoginAttempt(false);
                    
                    return;
                } 
                
                localStorage.setItem('isAuthenticated', 'true');
                navigate(`/search-engine/${client}`);
                
            } catch (err) {
                console.error('Unexpected error logging login attempt:', err);
                setGeneralError('Something went wrong. Please try again.');
                logLoginAttempt(false);
            }
        }
    };    

    const filterOption = (candidate, input) => {
        const searchValue = input.toLowerCase();
    
        // If label contains JSX, we extract text directly
        const label = typeof candidate.label === 'string' 
            ? candidate.label.toLowerCase()
            : candidate.label.props.children[1]?.toLowerCase() || ''; // Extract text from JSX
    
        const value = candidate.value.toLowerCase();
    
        // Match only if label or value starts with the search input
        return label.startsWith(searchValue) || value.startsWith(searchValue);
    };
    
    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);

    const pathSegments = window.location.pathname.split("/").filter(Boolean);
    const client = pathSegments.length > 1 ? pathSegments[1] : "safe-city";

    return (
      <div className="container">
          {/* Header */}
          <div className="header" style={{backgroundColor: '#ffffff'}}>
                <img src={clientConfig.logo} alt={`${clientConfig.name} Logo`} width="150" />
              {/* <img src={logo} alt="Safe City Parking" width="300" /> */}
          </div>
  
          {/* Main Content */}
          <div className="main-content">
              <form className="login-form" onSubmit={handleSubmit}>
                  <div className="form-header">To search your vehicle, enter the following details</div>
  
                  {/* License Plate Input */}
                  <div className="form-group">
                      <input
                            type="text"
                            value={licensePlate}
                            onChange={(e) => setLicensePlate(e.target.value)}
                            className="input-field"
                            placeholder="Enter license plate"
                        />
                  </div>
  
                  {/* Country Code and Mobile Number */}
                  {/* <div className="form-group">
  <PhoneInput
    country={'us'} // default
    value={mobileNumber}
    onChange={(phone, countryData) => {
      setMobileNumber(phone);
      setCountry({
        label: countryData.name,
        value: countryData.countryCode.toUpperCase()
      });
    }}
    inputClass="input-field"
    inputProps={{
      name: 'phone',
      required: true,
    }}
    enableSearch
  />
  {errors.mobileNumber && (
    <span className="error-text">
      Invalid mobile number
    </span>
  )}
</div> */}


                   <div className="form-inline-group">
                      <div className="form-inline-item">
                      <Select
                        options={options}
                        value={country}
                        onChange={handleCountryChange}
                        placeholder="Country Code"
                        isSearchable={true}
                        filterOption={filterOption}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                padding: '0px', // Reset padding
                                borderColor: state.isFocused || !errors.country ? '#ddd' : 'red',
                                boxShadow: 'none',
                                '&:hover': { borderColor: '#38ADEF' },
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: '#888', // Match placeholder color
                                textAlign: 'left',
                                fontSize: '1rem',
                            }),
                            input: (provided) => ({
                                ...provided,
                                margin: '0px', // Align input text with placeholder
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: '#333', // Text color after selection
                            }),
                        }}
                    />
                        {errors.country && <span className="error-text">Country is required</span>}
                      </div>
                      <div className="form-inline-item">
                          <input
                              type="text"
                              value={mobileNumber}
                              onChange={handleMobileNumberChange}
                              className={`input-field ${errors.mobileNumber ? 'invalid' : ''}`}
                              placeholder="Mobile Number"
                              maxLength={10}
                          />
                          {errors.mobileNumber && <span className="error-text">Must be 9-10 digits or in system</span>}
                      </div>
                  </div> 
  
                  {/* Terms and Conditions */}
                  <div className="terms-group" style={{marginTop: '20px'}}>
                      <input
                          type="checkbox"
                          checked={termsChecked}
                          onChange={(e) => setTermsChecked(e.target.checked)}
                      />
                      <label>I agree to the <a href="#" onClick={handleModalOpen}>terms and conditions</a></label>
                  </div>
  
                  {/* General Error Message */}
                  {generalError && (
                      <div className="error-message">
                          {generalError}
                      </div>
                  )}
  
                  {/* Submit Button */}
                  <button
                      type="submit"
                      className="submit-button"
                      disabled={!termsChecked || !licensePlate.length || !country || !mobileNumber}
                  >
                      Sign up
                  </button>
              </form>
          </div>
  
          {/* Footer clientConfig.primaryColor */}
        <Box sx={{ backgroundColor: '#38ADEF', padding: '20px', width: '100%', textAlign: 'center', marginTop: 'auto' }}>
            <Typography sx={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
            Contact us for support or refund of the towing fee:&nbsp;&nbsp;
            <Link href="mailto:claims@safecityparking.com" sx={{ color: 'white' }}>claims@safecityparking.com</Link>
            <br />
            <div style={{marginTop: '10px'}}>You can claim a refund even if the city isn't included in our search engine yet.</div>    
            </Typography>
        </Box>

        <TermsModal isOpen={isModalOpen} onClose={handleModalClose} client={client} />
      </div>
  );
  
};

export default Login;
