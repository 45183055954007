import React, { useEffect, useState } from 'react';
import './TermsModal.css';

const TermsModal = ({ isOpen, onClose, client }) => {
  const [termsContent, setTermsContent] = useState('');

  useEffect(() => {
    if (isOpen) {
      const clientFile = client === 'avis-israel' ? 'avis-israel' : 'safe-city';
      fetch(`/terms/${clientFile}.html`)
        .then(response => response.text())
        .then(data => setTermsContent(data))
        .catch(error => console.error('Error loading terms:', error));
    }
  }, [isOpen, client]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className={`modal-content ${client === 'avis-israel' ? 'rtl' : ''}`}>
        <button className="close-button" onClick={onClose}>X</button>
        <div dangerouslySetInnerHTML={{ __html: termsContent }} />
      </div>
    </div>
  );
};

export default TermsModal; 