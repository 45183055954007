import React, { useState, useEffect } from 'react';
import axios from 'axios';
import citiesData from './assets/cities.json';
import phoneNumberCodes from './assets/phone_numbers_code.json';
import { Box, Container, Typography, Table, TableBody, TableCell, 
  TableContainer, TableRow, Link, Divider, Grid, Button } from '@mui/material';
import Select from 'react-select';
import useClientConfig from "./useClientConfig";
import { supabase } from './supabaseClient';


const SearchEngine = () => {
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('not found');
  const [address, setAddress] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [openingHours, setOpeningHours] = useState('');
  const [towingFee, setTowingFee] = useState('');
  const [storageFee, setStorageFee] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [apiSearch, setApiSearch] = useState('');
  const [showCustomCityInput, setShowCustomCityInput] = useState(false);
  const [customCityName, setCustomCityName] = useState('');
  const [logSuccess, setLogSuccess] = useState(false);
  const [logError, setLogError] = useState(false);

  const clientConfig = useClientConfig();

  const countryOptions = [...new Set(citiesData.map((city) => city.Country))]
    .sort((a, b) => a.localeCompare(b))
    .map((country) => {
      const phoneCode = phoneNumberCodes[country] || '';
      return {
        value: country,
        label: `${country}${phoneCode ? ` (${phoneCode})` : ''}`,
        searchTerms: `${country} ${phoneCode}`.toLowerCase().replace('+', '')
      };
    });

  const cityOptions = selectedCountry
    ? citiesData
        .filter((city) => city.Country === selectedCountry)
        .sort((a, b) => a.City.localeCompare(b.City))
        .map((city) => ({
          value: city.City,
          label: city.City,
        }))
    : [];

  cityOptions.push({
    value: 'custom',
    label: 'Did not find your city?',
    isButton: true
  });

  /**
   * Renders a Google Maps clickable link for a given address tag.
   * @param {string} text - The input text containing {address} tags.
   * @returns {string} - Processed text with Google Maps clickable links.
   */
  const renderGoogleMapLinks = (text) => {
    const addressTagRegex = /\{address\}(.*?)\{address\}/g;

    return text.replace(addressTagRegex, (match, address) => {
      // Remove <strong> tags if present
      const cleanedAddress = address.replace(/<strong>|<\/strong>/g, '').trim();
      
      const googleMapsLink = `https://www.google.com/maps/search/${encodeURIComponent(cleanedAddress)}`;
      return `<a href="${googleMapsLink}" target="_blank" rel="noopener noreferrer" style="color: #38ADEF; text-decoration: none;">
                ${cleanedAddress}
              </a>`;
    });
  };

  /**
   * Renders clickable phone links for phone tags in the text.
   * @param {string} text - The input text containing {phone} tags.
   * @returns {string} - Processed text with clickable phone links.
   */
  const renderPhoneLinks = (text) => {
    const phoneTagRegex = /\{phone\}(.*?)\{phone\}/g;

    return text.replace(phoneTagRegex, (match, phone) => {
      const sanitizedPhone = phone.replace(/[-.\s]/g, ''); // Clean the phone number
      return `<a href="tel:${sanitizedPhone}" style="color: #38ADEF; text-decoration: none;">
                ${phone.trim()}
              </a>`;
    });
  };

  /**
   * Processes content to render clickable Google Maps links and phone numbers.
   * @param {string} text - The input text containing {address} and {phone} tags.
   * @returns {string} - Fully processed text with clickable links.
   */
  const processContent = (text) => {
    if (!text) return ''; // Guard clause for undefined text

    let processedText = renderGoogleMapLinks(text); // Process addresses first
    processedText = renderPhoneLinks(processedText); // Process phone numbers
    return processedText;
  };

  const updateCityDetails = (city) => {
    const cityDetails = citiesData.find((cityDetails) => cityDetails.City === city);

    if (cityDetails) {
      setAddress(cityDetails["Addresses"]);
      setContactPhone(cityDetails["Contact Phone"]);
      setOpeningHours(cityDetails["Opening Hours"]);
      setTowingFee(cityDetails["Towing Fee (regular size)"]);
      setStorageFee(cityDetails["Storage Fee (regular size)"]);
      setAdditionalInformation(cityDetails["more info"]);
      setApiSearch(cityDetails["API search"]);
    } else {
      console.error('City details not found.');
    }
  };

  // TODO: Add IP info token and implement only if geolocation is not available in the future
  /**
   * Fetches location from IP address.
   * @returns {Promise<Object>} - Location data with latitude and longitude.
   */
  const fetchLocationFromIP = async () => {
    try {
      const response = await axios.get("https://ipinfo.io/json?token=YOUR_IPINFO_TOKEN"); 
      const { city, country, loc } = response.data;
      const [latitude, longitude] = loc.split(",");

      setSelectedCity(city);
      setSelectedCountry(country);

      console.log(`Fallback IP-based location: ${city}, ${country}`);

      return { latitude, longitude };
    } catch (error) {
      console.error("Error fetching location from IP:", error);
      return null;
    }
  };

  const logCityAttempt = async (cityName) => {
    try {
      const pathSegments = window.location.pathname.split("/").filter(Boolean);
      const client = pathSegments.length > 1 ? pathSegments[1] : "safe-city";
      const ipRes = await fetch('https://api.ipify.org?format=json');
      const { ip } = await ipRes.json();
  
      const { error } = await supabase.from('new_cities').insert([
        {
          city_name: cityName,
          ip_address: ip,
          company: client,
          country_code: selectedCountry,
        }
      ]);
  
      if (error) {
        console.error('Error logging city attempt:', error.message);
        return false;
      }
      return true;
    } catch (err) {
      console.error('Unexpected error logging city attempt:', err);
      return false;
    }
  };
  

  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;
          
          // Use Nominatim API for reverse geocoding
          axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1&accept-language=en`)
            .then(response => {
              console.log(response.data);

              const address = response.data.address;
              // const countryShort = address.country_code.toUpperCase();
              let cityName = address.city || address.town || address.village;
              setSelectedCity(cityName.replace("-"," "));

              const countryName = address.country
              setSelectedCountry(countryName)

              const cityDetails = citiesData.find(city => city.City === cityName); 
              if (cityDetails) {
                setSelectedCountry(cityDetails.Country);
                updateCityDetails(cityName);

              } else {
                console.error('City not found for the given location.');

              }
            })
            .catch(error => {
              console.error("Error reverse geocoding location:", error);
            });
        }, error => {
          console.error("Error getting geolocation:", error);
        });
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    if (!selectedCountry || selectedCity === 'not found') {
      return;
    }

    const cityDetails = citiesData.find(city => city.City === selectedCity && city.Country === selectedCountry);

    if (cityDetails) {
      updateCityDetails(selectedCity);
    } else {
      console.log('Selected city does not match the selected country.');
    }    

  }, [selectedCountry, selectedCity]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.value);
    setSelectedCity('not found');
    setAddress('');
    setContactPhone('');
    setOpeningHours('');
    setTowingFee('');
    setStorageFee('');
    setAdditionalInformation('');
    setApiSearch('');
  };

  const handleCityChange = (selectedOption) => {
    if (selectedOption.value === 'custom') {
      setShowCustomCityInput(true);
    } else {
      setSelectedCity(selectedOption.value);
      setShowCustomCityInput(false);
    }
  };

  const handleCustomCitySubmit = async () => {
    const success = await logCityAttempt(customCityName);
    if (success) {
      setLogSuccess(true);
      setLogError(false);
    } else {
      setLogError(true);
    }
  };

  const filterOption = (candidate, input) => {
    if (!input) return true;
    
    const searchValue = input.toLowerCase().trim().replace('+', '');
    const searchableLabel = (candidate?.label || '').toLowerCase();
    const searchableTerms = (candidate?.searchTerms || '');
    
    return searchableLabel.includes(searchValue) || searchableTerms.includes(searchValue);
  };

  const handleLocationLinkClick = () => {
    setShowLocationSelector(true);
  };

  if (!clientConfig) {
    return <div>Loading...</div>; // Prevents the error
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%', minHeight: '100vh', overflow: 'auto' }}>
      <Box sx={{ backgroundColor: '#ffffff', padding: '20px', width: '100%', textAlign: 'center' }}>
        <img src={clientConfig.logo} alt={`${clientConfig.name} Logo`} width="150" />
        {/* <img src={logo} alt="Safe City Parking" width="300" /> */}
      </Box>

      <Container sx={{ width: '100%', margin: '0px', padding: '0px' }}>
        <Box sx={{ padding: '10px' }}>
          <section>
            {showLocationSelector && (
              <Box>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                  {/* Country Dropdown */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: '500',
                        color: '#555',
                        marginBottom: '4px',
                        textAlign: 'left',
                      }}
                    >
                      Select a Country
                    </Typography>
                    <Select
                      options={countryOptions}
                      value={countryOptions.find(option => option.value === selectedCountry)}
                      onChange={handleCountryChange}
                      placeholder="Select a country"
                      isSearchable={true}
                      filterOption={filterOption}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          height: '36px',
                          minHeight: '36px',
                          backgroundColor: '#fff',
                          borderRadius: '6px',
                          fontSize: '0.875rem',
                          borderColor: state.isFocused ? '#38ADEF' : '#ddd',
                          boxShadow: 'none',
                          '&:hover': { borderColor: '#aaa' },
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#888',
                          textAlign: 'left',
                          fontSize: '0.875rem',
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: '0px',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: '#333',
                          textAlign: 'left',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          textAlign: 'left',
                          backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
                          '&:hover': {
                            backgroundColor: '#f0f0f0',
                          },
                        }),
                      }}
                    />
                  </Grid>

                  {/* City Dropdown */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: '500',
                        color: '#555',
                        marginBottom: '4px',
                        textAlign: 'left',
                      }}
                    >
                      Select a City
                    </Typography>
                    <Select
                      options={cityOptions}
                      value={selectedCity === 'not found' ? null : cityOptions.find(option => option.value === selectedCity)}
                      onChange={handleCityChange}
                      placeholder="Select a city"
                      isSearchable={true}
                      filterOption={filterOption}
                      isDisabled={!selectedCountry}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          height: '36px',
                          minHeight: '36px',
                          backgroundColor: '#fff',
                          borderRadius: '6px',
                          fontSize: '0.875rem',
                          borderColor: state.isFocused ? '#38ADEF' : '#ddd',
                          boxShadow: 'none',
                          '&:hover': { borderColor: '#aaa' },
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#888',
                          textAlign: 'left',
                          fontSize: '0.875rem',
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: '0px',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: '#333',
                          textAlign: 'left',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          textAlign: 'left',
                          backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
                          '&:hover': {
                            backgroundColor: '#f0f0f0',
                          },
                        }),
                      }}
                    />
                    {showCustomCityInput && (
                      <Box sx={{ mt: 2 }}>
                        {logSuccess ? (
                          <Typography sx={{ color: '#38ADEF', fontWeight: 'bold', textAlign: 'center' }}>
                            Thank you. If eligible, you will still be reimbursed even if your Location is not listed here.
                          </Typography>
                        ) : logError ? (
                          <Typography sx={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>
                            Something went wrong, please try again later.
                          </Typography>
                        ) : (
                          <>
                            <Divider sx={{ my: 2, borderColor: 'primary.main', borderWidth: '1px' }} />
                            <input
                              type="text"
                              placeholder="Enter your city"
                              value={customCityName}
                              onChange={(e) => setCustomCityName(e.target.value)}
                              style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '6px',
                                border: '1px solid #ddd',
                                fontSize: '0.875rem',
                                color: '#333',
                                marginBottom: '8px',
                                boxSizing: 'border-box',
                                outline: 'none',
                                '&:focus': {
                                  borderColor: '#38ADEF',
                                },
                              }}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleCustomCitySubmit}
                              sx={{
                                width: '100%',
                                padding: '8px',
                                fontSize: '0.875rem',
                                textTransform: 'none',
                                backgroundColor: '#38ADEF',
                                '&:hover': {
                                  backgroundColor: '#2a8bbf',
                                },
                              }}
                            >
                              Submit
                            </Button>
                          </>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
              </Box>
            )}

            {!showLocationSelector && (
            <Box id="serviceFound" sx={{ mt: 2 }}>
              <Typography><span style={{ fontWeight: 700}}>Your location: </span><span>{selectedCity}</span>{selectedCountry && <span>, </span>}<span>{selectedCountry}</span></Typography>
              <Link href="#" sx={{  display: 'block' }} onClick={handleLocationLinkClick}>Not your location?</Link>
            </Box>
            )}

            <Box id="searchResults" sx={{ display: 'none', mt: 2 }}>
              <Box id="found" sx={{ display: 'none' }}>
                <Typography id="searchResultText"></Typography>
              </Box>
              <Box id="notfound" sx={{ display: 'none' }}>
                <Typography id="searchResultText1"></Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 2, borderColor: 'primary.main', borderWidth: '1px' }} />

            <Box sx={{ mt: 0 }} style={{marginBottom: '20px', marginTop: '20px'}}>
              {apiSearch && (
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(apiSearch, '_blank')}
                  >
                    Search Vehicle Online
                  </Button>
                </div>
              )}
            </Box>

            <Box id="serviceDetails" sx={{ mt: 2 }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Address:</TableCell>
                      <TableCell id="serviceAddress" style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processContent(address),
                        }}
                      />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Contact phone:</TableCell>
                      <TableCell id="servicePhone" style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: processContent(contactPhone),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Opening hours:</TableCell>
                      <TableCell id="serviceHours" style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: processContent(openingHours),
                          }}
                        />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Towing fee:</TableCell>
                      <TableCell id="serviceTowingFee" style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: processContent(towingFee),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Storage fee:</TableCell>
                      <TableCell id="serviceStorageFee" style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: processContent(storageFee),
                          }}
                        />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Additional information:</TableCell>
                      <TableCell id="serviceInfo" style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processContent(additionalInformation),
                        }}
                      />
                      </TableCell>                    
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </section>
        </Box>
      </Container>

      <Box sx={{ backgroundColor: '#38ADEF', padding: '20px', width: '100%', textAlign: 'center', marginTop: 'auto' }}>
        <Typography sx={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
          Contact us for support or refund of the towing fee:&nbsp;&nbsp;
          <Link href="mailto:claims@safecityparking.com" sx={{ color: 'white' }}>claims@safecityparking.com</Link>
          <br />
          <div style={{marginTop: '10px'}}>You can claim a refund even if the city isn't included in our search engine yet.</div>    
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchEngine;